import React, { useState } from "react";
import {
    Table,
    Button,
    Modal,
    Form,
    Input,
    message,
    Card,
    Tag,
    Space,
    Tooltip,
    Typography,
    Divider,
} from "antd";
import { useTranslation } from "react-i18next";
import { useList, useCreate, BaseRecord, useDeleteMany } from "@refinedev/core";
import { DeleteButton } from "@refinedev/antd";
import {
    LinkOutlined,
    PlusOutlined,
    FileTextOutlined,
    MailOutlined,
} from "@ant-design/icons";
import { copyToClipboard } from "utilities/clipboard";
import { generateBulkInviteEmail } from "utilities/invite-email-generator";

interface InviteData {
    id: number;
    email: string;
    token: string;
    created_at: string;
    is_used: boolean;
    invite_url: string;
    group_name: string;
    name?: string;
    role?: string;
}

interface InvitesTableProps {
    userGroupId: number;
    isEditable: boolean;
}

interface ErrorResponse {
    response?: {
        data?: {
            error?: string;
        };
    };
}

export const InvitesTable: React.FC<InvitesTableProps> = ({
    userGroupId,
    isEditable,
}) => {
    const { t } = useTranslation();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();
    const [selectedInviteIds, setSelectedInviteIds] = useState<React.Key[]>([]);
    const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });

    const { data, isLoading, refetch } = useList<InviteData>({
        resource: `user-groups/${userGroupId}/invites`,
        queryOptions: {
            enabled: !!userGroupId,
        },
        pagination: {
            mode: 'server',
            current: pagination.current,
            pageSize: pagination.pageSize,
        },
        meta: {
            offset: (pagination.current - 1) * pagination.pageSize,
            limit: pagination.pageSize,
        },
    });

    const { mutate: createInvite, isLoading: isCreating } = useCreate();
    const { mutate: deleteMany } = useDeleteMany();

    const handleCopyLink = (url: string) => {
        copyToClipboard(url);
        message.success(
            t("invites.linkCopied", "Invite link copied to clipboard")
        );
    };

    const handleCopyInviteText = (data: InviteData) => {
        const inviteText = t(
            "invites.inviteTextFull",
            "Pozdravljeni{{nameGreeting}},\n\nPovabljeni ste k pridružitvi {{groupName}}{{roleText}}. Kliknite spodnjo povezavo za ustvarjanje računa:\n\n{{inviteUrl}}\n\nHvala in lep pozdrav!",
            {
                nameGreeting: data.name ? ` ${data.name}` : "",
                groupName: data.group_name,
                roleText: data.role
                    ? t("invites.asRole", " kot {{role}}", { role: data.role })
                    : "",
                inviteUrl: data.invite_url,
            }
        );

        copyToClipboard(inviteText);
        message.success(
            t("invites.textCopied", "Invite text copied to clipboard")
        );
    };

    const columns = [
        {
            title: t("invites.email"),
            dataIndex: "email",
            key: "email",
        },
        {
            title: t("invites.name"),
            dataIndex: "name",
            key: "name",
            render: (name: string) => name || "-",
        },
        {
            title: t("invites.role"),
            dataIndex: "role",
            key: "role",
            render: (role: string) => role || "-",
        },
        {
            title: t("invites.status"),
            dataIndex: "is_used",
            key: "is_used",
            render: (isUsed: boolean) => (
                <Tag color={isUsed ? "red" : "green"}>
                    {isUsed ? t("invites.used") : t("invites.unused")}
                </Tag>
            ),
        },
        {
            title: t("invites.createdAt"),
            dataIndex: "created_at",
            key: "created_at",
            render: (date: string) => new Date(date).toLocaleString("sl-SI"),
        },
        {
            title: t("invites.actions"),
            key: "actions",
            render: (_: unknown, record: InviteData) => (
                <Space>
                    {!record.is_used && (
                        <>
                            <Tooltip
                                title={t(
                                    "invites.copyLink",
                                    "Copy invite link"
                                )}
                            >
                                <Button
                                    icon={<LinkOutlined />}
                                    size="small"
                                    onClick={() =>
                                        handleCopyLink(record.invite_url)
                                    }
                                />
                            </Tooltip>
                            <Tooltip
                                title={t(
                                    "invites.copyInviteText",
                                    "Copy invite text with link"
                                )}
                            >
                                <Button
                                    icon={<FileTextOutlined />}
                                    size="small"
                                    onClick={() => handleCopyInviteText(record)}
                                />
                            </Tooltip>
                        </>
                    )}
                    {isEditable && (
                        <DeleteButton
                            size="small"
                            hideText
                            resource={`user-groups/${userGroupId}/invites`}
                            recordItemId={String(selectedInviteIds[0])}
                            onSuccess={() => {
                                refetch();
                                setSelectedInviteIds([]);
                            }}
                        />
                    )}
                </Space>
            ),
        },
    ];

    const handleCreateInvite = () => {
        form.validateFields().then((values) => {
            createInvite(
                {
                    resource: `user-groups/${userGroupId}/invites`,
                    values: {
                        email: values.email,
                        name: values.name || "",
                        role: values.role || "",
                        group: userGroupId,
                    },
                },
                {
                    onSuccess: (data: BaseRecord) => {
                        setIsModalVisible(false);
                        form.resetFields();
                        refetch();

                        // Copy the invite link to clipboard
                        const inviteUrl = data?.data?.invite_url;
                        if (inviteUrl) {
                            handleCopyLink(inviteUrl);
                        }

                        message.success(
                            t(
                                "invites.created",
                                "Invite created and link copied to clipboard"
                            )
                        );
                    },
                    onError: (error: ErrorResponse) => {
                        message.error(
                            error?.response?.data?.error ||
                                t(
                                    "invites.createError",
                                    "Error creating invite"
                                )
                        );
                    },
                }
            );
        });
    };

    const rowSelection = isEditable
        ? {
              selectedRowKeys: selectedInviteIds,
              onChange: (selectedRowKeys: React.Key[]) => {
                  setSelectedInviteIds(selectedRowKeys);
              },
          }
        : undefined;

    return (
        <Card
            className="mt-4"
            styles={{
                body: {
                    padding: 0,
                },
            }}
            title={
                <div className="flex justify-between items-center">
                    <span>{t("userGroupForm.fields.invites", "Invites")}</span>
                    {isEditable && (
                        <Space>
                            {selectedInviteIds.length > 0 && (
                                <Button
                                    danger
                                    onClick={() => {
                                        Modal.confirm({
                                            title: t("invites.deleteConfirmTitle", "Delete Invites"),
                                            content: t("invites.deleteConfirmContent", {
                                                count: selectedInviteIds.length,
                                                defaultValue: "Are you sure you want to delete {{count}} invite(s)?",
                                            }),
                                            okText: t("common.yes", "Yes"),
                                            cancelText: t("common.no", "No"),
                                            okButtonProps: { danger: true },
                                            onOk: () => {
                                                deleteMany(
                                                    {
                                                        resource: `user-groups/${userGroupId}/invites`,
                                                        ids: selectedInviteIds.map(String),
                                                    },
                                                    {
                                                        onSuccess: () => {
                                                            refetch();
                                                            setSelectedInviteIds([]);
                                                            message.success(t("invites.deleteSuccess", "Invites deleted successfully"));
                                                        },
                                                    }
                                                );
                                            },
                                        });
                                    }}
                                >
                                    {t("invites.deleteSelected", {
                                        count: selectedInviteIds.length,
                                    })}
                                </Button>
                            )}
                            <Tooltip title={t("invites.generateEmail", "Generate email with all invite links")}>
                                <Button
                                    icon={<MailOutlined />}
                                    onClick={() => {
                                        if (!data?.data) return;
                                        const emailContent = generateBulkInviteEmail(data.data, t);
                                        if (emailContent) {
                                            copyToClipboard(emailContent);
                                            message.success(t("invites.emailCopied", "Email content copied to clipboard"));
                                        } else {
                                            message.info(t("invites.noActiveInvites", "No active invites to include in email"));
                                        }
                                    }}
                                >
                                    {t("invites.copyAllInviteLinks", "Copy all")}
                                </Button>
                            </Tooltip>
                            <Button
                                type="primary"
                                icon={<PlusOutlined />}
                                onClick={() => setIsModalVisible(true)}
                            >
                                {t("invites.create", "Create Invite")}
                            </Button>
                        </Space>
                    )}
                </div>
            }
        >
            <Table
                dataSource={data?.data || []}
                columns={columns}
                loading={isLoading}
                rowKey="id"
                rowSelection={rowSelection}
                scroll={{ x: "max-content" }}
                pagination={{
                    current: pagination.current,
                    pageSize: pagination.pageSize,
                    total: data?.total,
                    onChange: (current, pageSize) => {
                        setPagination({ current, pageSize });
                    },
                    showSizeChanger: true,
                    showTotal: (total) => t("pagination.total", "Total {{total}} items", { total }),
                    locale: {
                        items_per_page: t("pagination.itemsPerPage", "/ page"),
                        jump_to: t("pagination.jumpTo", "Go to"),
                        jump_to_confirm: t("pagination.jumpToConfirm", "confirm"),
                        page: t("pagination.page", "Page"),
                        prev_page: t("pagination.prevPage", "Previous Page"),
                        next_page: t("pagination.nextPage", "Next Page"),
                        prev_5: t("pagination.prev5", "Previous 5 Pages"),
                        next_5: t("pagination.next5", "Next 5 Pages"),
                    },
                    className: "[&_.ant-pagination-options]:mr-6"
                }}
            />

            <Modal
                title={t("invites.createModalTitle", "Create New Invite")}
                open={isModalVisible}
                onOk={handleCreateInvite}
                onCancel={() => {
                    setIsModalVisible(false);
                    form.resetFields();
                }}
                confirmLoading={isCreating}
            >
                <Typography.Paragraph>
                    {t(
                        "invites.createDescription",
                        "Create an invite for a new user. The invite link will be copied to your clipboard and you can then send it to the user via any communication channel."
                    )}
                </Typography.Paragraph>
                <Form form={form} layout="vertical">
                    <Form.Item
                        name="email"
                        label={t("invites.emailLabel", "Email")}
                        rules={[
                            {
                                required: true,
                                message: t(
                                    "invites.emailRequired",
                                    "Please enter an email"
                                ),
                            },
                            {
                                type: "email",
                                message: t(
                                    "invites.invalidEmail",
                                    "Please enter a valid email"
                                ),
                            },
                        ]}
                    >
                        <Input
                            placeholder={t(
                                "invites.emailPlaceholder",
                                "Enter user's email"
                            )}
                        />
                    </Form.Item>

                    <Divider>
                        {t("invites.optionalInfo", "Optional Information")}
                    </Divider>

                    <Form.Item
                        name="name"
                        label={t("invites.nameLabel", "Name")}
                    >
                        <Input
                            placeholder={t(
                                "invites.namePlaceholder",
                                "Enter user's name (optional)"
                            )}
                        />
                    </Form.Item>

                    <Form.Item
                        name="role"
                        label={t("invites.roleLabel", "Role")}
                    >
                        <Input
                            placeholder={t(
                                "invites.rolePlaceholder",
                                "Enter user's role (optional)"
                            )}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </Card>
    );
};
