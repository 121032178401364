import React, { useEffect, useState } from "react";
import { useTranslate, useCustomMutation, useApiUrl } from "@refinedev/core";
import { ThemedTitleV2 } from "components/layout/title";
import { Form, Input, Button, Card, Typography, Layout, Row, Col, message, Spin } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import { authProvider } from "../../authProvider";

interface InviteDetails {
  email: string;
  group: string;
  name?: string;
  role?: string;
}

interface FormValues {
  email: string;
  name: string;
  role: string;
  password: string;
  confirmPassword: string;
}

interface ErrorResponse {
  response?: {
    data?: {
      error?: string;
      errors?: {
        email?: string;
        [key: string]: string | undefined;
      };
    };
  };
}

interface FieldEditState {
  email: boolean;
  name: boolean;
  role: boolean;
}

export const AcceptInvitePage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [inviteDetails, setInviteDetails] = useState<InviteDetails | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [fieldEditState, setFieldEditState] = useState<FieldEditState>({
    email: false,
    name: true,
    role: true,
  });
  const apiUrl = useApiUrl();
  const t = useTranslate();
  const navigate = useNavigate();
  const { mutate: acceptInvite, isLoading: isSubmitting } = useCustomMutation();
  const [form] = Form.useForm<FormValues>();

  useEffect(() => {
    if (!token) {
      setError(t("pages.acceptInvite.errors.noToken", "No invite token provided"));
      setIsLoading(false);
      return;
    }

    fetch(`${apiUrl}/accept-invite/?token=${token}`)
      .then(response => {
        if (!response.ok) {
          throw new Error("Invalid or expired invite");
        }
        return response.json();
      })
      .then((data: InviteDetails) => {
        console.log("Received invite details:", data);
        setInviteDetails(data);

        const formValues: Partial<FormValues> = {
          email: data.email
        };

        const newFieldEditState = { ...fieldEditState };

        if (data.name) {
          formValues.name = data.name;
          newFieldEditState.name = false;
        }

        if (data.role) {
          formValues.role = data.role;
          newFieldEditState.role = false;
        }

        form.setFieldsValue(formValues);
        setFieldEditState(newFieldEditState);
        setIsLoading(false);
      })
      .catch(err => {
        console.error("Error fetching invite details:", err);
        setError(err.message || "Failed to load invite details");
        setIsLoading(false);
      });
  }, [token, apiUrl, t]);

  const toggleFieldEdit = (field: keyof FieldEditState) => {
    setFieldEditState(prev => ({
      ...prev,
      [field]: !prev[field]
    }));
  };

  const onFinish = (values: FormValues) => {
    acceptInvite(
      {
        url: `${apiUrl}/accept-invite/`,
        method: "post",
        values: { ...values, token },
      },
      {
        onSuccess: async () => {
          try {
            const loginResponse = await authProvider.login({
              email: values.email,
              password: values.password,
            });

            if (loginResponse.success) {
              message.success(t(
                "pages.acceptInvite.success",
                "Account created successfully! You are now logged in."
              ));
              navigate("/");
            } else {
              message.success(t(
                "pages.acceptInvite.successButLoginFailed",
                "Account created successfully! Please log in with your credentials."
              ));
              navigate("/login");
            }
          } catch (error) {
            message.success(t(
              "pages.acceptInvite.successButLoginFailed",
              "Account created successfully! Please log in with your credentials."
            ));
            navigate("/login");
          }
        },
        onError: (error: ErrorResponse) => {
          const errorMessage = error?.response?.data?.error ||
            error?.response?.data?.errors?.email ||
            t("pages.acceptInvite.errors.generic", "An error occurred while creating your account");

          message.error(errorMessage);
        },
      }
    );
  };

  if (isLoading) {
    return (
      <Layout className="min-h-screen bg-gray-100">
        <Row justify="center" align="middle" className="min-h-screen">
          <Col>
            <Spin size="large" />
            <Typography.Text className="block mt-4">
              {t("pages.acceptInvite.loading", "Loading invite details...")}
            </Typography.Text>
          </Col>
        </Row>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout className="min-h-screen bg-gray-100">
        <Row justify="center" align="middle" className="min-h-screen">
          <Col xs={22} sm={16} md={12} lg={10}>
            <Card>
              <Typography.Title level={4} className="text-center text-red-500">
                {t("pages.acceptInvite.errors.title", "Error")}
              </Typography.Title>
              <Typography.Paragraph className="text-center">
                {error}
              </Typography.Paragraph>
              <div className="text-center mt-4">
                <Button type="primary" onClick={() => navigate("/login")}>
                  {t("pages.acceptInvite.goToLogin", "Go to Login")}
                </Button>
              </div>
            </Card>
          </Col>
        </Row>
      </Layout>
    );
  }

  return (
    <Layout className="min-h-screen bg-gray-100">
      <Row justify="center" align="middle" className="min-h-screen">
        <Col xs={22} sm={16} md={12} lg={10}>
          <div className="text-center mb-8">
            <ThemedTitleV2 collapsed={false} />
          </div>
          <Card
            title={
              <Typography.Title level={3} className="text-center my-4">
                {t("pages.acceptInvite.title", "Create Your Account")}
              </Typography.Title>
            }
          >
            {inviteDetails && (
              <div className="mb-6">
                <Typography.Paragraph className="text-center">
                  {inviteDetails.group
                    ? t("pages.acceptInvite.invitationWithGroup", "You've been invited to join {{group}}. Complete the form below to create your account.",
                        { group: inviteDetails.group })
                    : t("pages.acceptInvite.invitationNoGroup", "You've been invited. Complete the form below to create your account.")}
                </Typography.Paragraph>
              </div>
            )}

            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
              requiredMark={false}
            >
              <Form.Item
                label={t("pages.acceptInvite.fields.email", "Email")}
                name="email"
                rules={[
                  { required: true, message: t("pages.acceptInvite.errors.emailRequired", "Email is required") },
                  { type: "email", message: t("pages.acceptInvite.errors.invalidEmail", "Invalid email format") }
                ]}
              >
                <Input
                  disabled={!fieldEditState.email}
                  suffix={
                    <Button
                      size="small"
                      icon={<EditOutlined />}
                      onClick={() => toggleFieldEdit("email")}
                      className={`flex items-center ${!fieldEditState.email ? 'bg-white hover:bg-gray-50' : 'bg-gray-100'}`}
                    >
                      {fieldEditState.email ? t("pages.acceptInvite.done", "Done") : t("pages.acceptInvite.edit", "Edit")}
                    </Button>
                  }
                />
              </Form.Item>

              <Form.Item
                label={t("pages.acceptInvite.fields.name", "Full Name")}
                name="name"
                rules={[
                  { required: true, message: t("pages.acceptInvite.errors.nameRequired", "Name is required") }
                ]}
              >
                <Input
                  disabled={!fieldEditState.name}
                  placeholder={t("pages.acceptInvite.placeholders.name", "Enter your full name")}
                  suffix={
                    inviteDetails?.name && (
                      <Button
                        size="small"
                        icon={<EditOutlined />}
                        onClick={() => toggleFieldEdit("name")}
                        className={`flex items-center ${!fieldEditState.name ? 'bg-white hover:bg-gray-50' : 'bg-gray-100'}`}
                      >
                        {fieldEditState.name ? t("pages.acceptInvite.done", "Done") : t("pages.acceptInvite.edit", "Edit")}
                      </Button>
                    )
                  }
                />
              </Form.Item>

              <Form.Item
                label={t("pages.acceptInvite.fields.role", "Role")}
                name="role"
                rules={[
                  { required: true, message: t("pages.acceptInvite.errors.roleRequired", "Role is required") }
                ]}
              >
                <Input
                  disabled={!fieldEditState.role}
                  placeholder={t("pages.acceptInvite.placeholders.role", "Enter your role (e.g., Manager, Accountant)")}
                  suffix={
                    inviteDetails?.role && (
                      <Button
                        size="small"
                        icon={<EditOutlined />}
                        onClick={() => toggleFieldEdit("role")}
                        className={`flex items-center ${!fieldEditState.role ? 'bg-white hover:bg-gray-50' : 'bg-gray-100'}`}
                      >
                        {fieldEditState.role ? t("pages.acceptInvite.done", "Done") : t("pages.acceptInvite.edit", "Edit")}
                      </Button>
                    )
                  }
                />
              </Form.Item>

              <Form.Item
                name="password"
                label={t("pages.acceptInvite.fields.password", "Password")}
                rules={[
                  { required: true, message: t("pages.acceptInvite.errors.passwordRequired", "Password is required") },
                  { min: 8, message: t("pages.acceptInvite.errors.passwordTooShort", "Password must be at least 8 characters") },
                  { pattern: /[A-Z]/, message: t("pages.acceptInvite.errors.passwordUppercase", "Password must contain at least one uppercase letter") },
                  { pattern: /[a-z]/, message: t("pages.acceptInvite.errors.passwordLowercase", "Password must contain at least one lowercase letter") },
                  { pattern: /[0-9]/, message: t("pages.acceptInvite.errors.passwordNumber", "Password must contain at least one number") },
                  { pattern: /[!@#$%^&*(),.?":{}|<>]/, message: t("pages.acceptInvite.errors.passwordSpecial", "Password must contain at least one special character") }
                ]}
              >
                <Input.Password placeholder="●●●●●●●●" />
              </Form.Item>

              <Form.Item
                name="confirmPassword"
                label={t("pages.acceptInvite.fields.confirmPassword", "Confirm Password")}
                dependencies={["password"]}
                rules={[
                  { required: true, message: t("pages.acceptInvite.errors.confirmPasswordRequired", "Please confirm your password") },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(t("pages.acceptInvite.errors.passwordsDoNotMatch", "The two passwords do not match")));
                    },
                  }),
                ]}
              >
                <Input.Password placeholder="●●●●●●●●" />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  block
                  loading={isSubmitting}
                >
                  {t("pages.acceptInvite.createAccount", "Create Account")}
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
};
