import React, { useState } from "react";
import {
    Modal,
    Button,
    Table,
    Form,
    Select,
    Card,
    Typography,
    Row,
    DatePicker,
} from "antd";
import { useDeleteMany } from "@refinedev/core";
import { useTranslation } from "react-i18next";
import { useSelect, useTable } from "@refinedev/antd";
import { HttpError, useCreate } from "@refinedev/core";
import { PlusCircleOutlined } from "@ant-design/icons";

const { Option } = Select;

interface IAccess {
    id: number;
    user_group: string;
    content_type: string;
    object_id: number;
    content_object: string;
    permission: string;
    trial_expiration_date: string | null; // Add this line
}

const CreateAccess = ({
    userGroupId,
    isModalVisible,
    setIsModalVisible,
}: {
    userGroupId: number;
    isModalVisible: boolean;
    setIsModalVisible: (visible: boolean) => void;
}) => {
    const [selectedContentType, setSelectedContentType] = useState("");

    const { mutate: mutateCreateAccess } = useCreate();

    interface CreateAccessValues {
        object_id: string;
        permission: string;
        content_type: string;
        trial_expiration_date?: string;
    }

    const handleCreate = (values: CreateAccessValues) => {
        mutateCreateAccess(
            {
                resource: "access",
                values: {
                    object_id: values.object_id,
                    permission: values.permission,
                    content_type: values.content_type,
                    trial_expiration_date: values.trial_expiration_date,
                },
                meta: {
                    userGroupId,
                },
            },
            {
                onSuccess: () => {
                    setIsModalVisible(false);
                },
            }
        );
    };

    interface CreateAccessTypes {
        content_type: string;
        object_id: string;
        permission: string;
    }

    const [form] = Form.useForm<CreateAccessTypes>();

    const handleContentTypeChange = (value: string) => {
        setSelectedContentType(value);
        form.resetFields(["object_id"]);
    };

    const contentTypes = [
        { id: "content-documents", name: "Content Document" },
        { id: "assistants", name: "Assistant" },
    ];

    const { selectProps } = useSelect({
        resource: selectedContentType,
        queryOptions: {
            enabled: selectedContentType !== "",
        },
    });

    const { t } = useTranslation();

    return (
        <div>
            <Button
                icon={<PlusCircleOutlined />}
                onClick={() => setIsModalVisible(true)}
            >
                {t("buttons.create", "Create")}
            </Button>
            <Modal
                title={t("createAccess.title", "Create Access")}
                open={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                footer={null}
            >
                <Form<CreateAccessValues> onFinish={handleCreate} form={form}>
                    <Form.Item
                        name="content_type"
                        label={t("createAccess.contentType", "Content Type")}
                        rules={[{ required: true }]}
                    >
                        <Select
                            value={selectedContentType}
                            onChange={handleContentTypeChange}
                        >
                            {contentTypes.map((type) => (
                                <Option key={type.id} value={type.id}>
                                    {t(
                                        `contentTypes.${type.name.toLowerCase()}`,
                                        type.name
                                    )}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="object_id"
                        label={t("createAccess.entity", "Entity")}
                        rules={[{ required: true }]}
                    >
                        <Select
                            disabled={selectedContentType === ""}
                            {...selectProps}
                        />
                    </Form.Item>
                    <Form.Item
                        name="permission"
                        label={t("createAccess.permission", "Permission")}
                        rules={[{ required: true }]}
                        initialValue="view"
                        hidden
                    >
                        <Select disabled>
                            <Option value="view">
                                {t("createAccess.view", "View")}
                            </Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="trial_expiration_date"
                        label={t(
                            "createAccess.trialEndDate",
                            "Trial End Date (Optional)"
                        )}
                    >
                        <DatePicker allowClear />
                    </Form.Item>
                    <Form.Item>
                        <div className="flex justify-end">
                            <Button type="primary" htmlType="submit">
                                {t("createAccess.create", "Create")}
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

const AccessTable = ({
    userGroupId,
    isEditable = false,
}: {
    userGroupId: number;
    isEditable?: boolean;
}) => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>(
        []
    );
    const {
        mutate,
        isSuccess,
        isLoading: deleteManyIsLoading,
    } = useDeleteMany<IAccess>();

    const deleteSelectedItems = () => {
        mutate({
            resource: "access",
            ids: selectedRowKeys.map(String),
            meta: {
                userGroupId,
            },
        });
    };

    React.useEffect(() => {
        if (isSuccess) {
            setSelectedRowKeys([]);
        }
    }, [isSuccess]);

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(selectedRowKeys);
    };

    const { t } = useTranslation();

    const rowSelection = isEditable
        ? {
              selectedRowKeys,
              onChange: onSelectChange,
              selections: [
                  Table.SELECTION_ALL,
                  Table.SELECTION_INVERT,
                  Table.SELECTION_NONE,
              ],
          }
        : undefined;
    const hasSelected = selectedRowKeys.length > 0;

    const columns = [
        {
            title: t("accessTable.columns.contentType", "Content Type"),
            dataIndex: "content_type_name",
            key: "content_type_name",
            render: (value: string) => {
                const translatedValue = t(`contentTypes.${value}`, value);
                return <span>{translatedValue}</span>;
            },
        },
        {
            title: t("accessTable.columns.objectId", "Object ID"),
            dataIndex: "object_id",
            key: "object_id",
        },
        {
            title: t("accessTable.columns.objectName", "Object Name"),
            dataIndex: "object_name",
            key: "object_name",
        },
        {
            title: t("accessTable.columns.updatedAt", "Updated At"),
            dataIndex: "updated_at",
            key: "updated_at",
            render: (value: string) => {
                return new Date(value).toLocaleDateString("sl-SI");
            },
        },
        {
            title: t("accessTable.columns.trial", "Trial"),
            dataIndex: "trial_expiration_date",
            key: "trial_expiration_date",
            render: (value: string | null) => {
                if (!value) return null;
                return (
                    <span>{`${t(
                        "accessTable.columns.trialUntil",
                        "Trial until"
                    )}  ${new Date(value).toLocaleDateString("sl-SI")}`}</span>
                );
            },
        },
    ];

    const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });

    const { tableProps } = useTable<IAccess, HttpError>({
        resource: "access",
        meta: {
            userGroupId,
            offset: (pagination.current - 1) * pagination.pageSize,
            limit: pagination.pageSize,
        },
        pagination: {
            mode: 'server',
            current: pagination.current,
            pageSize: pagination.pageSize,
        },
        filters: {
            permanent: [
                {
                    field: "user_group",
                    operator: "eq",
                    value: userGroupId.toString(),
                },
            ],
        },
        queryOptions: {
            enabled: !!userGroupId,
        },
    });

    return (
        <div>
            <Card
                className="mt-4"
                styles={{
                    body: {
                        padding: 0,
                    },
                }}
            >
                <Row justify="space-between" className="p-4">
                    <Typography.Title level={5}>
                        {t("accessTable.title", "Access Table")}
                    </Typography.Title>{" "}
                    <div className="flex-grow" />
                    {isEditable && hasSelected && (
                        <Button
                            className="mr-2"
                            danger
                            onClick={deleteSelectedItems}
                            disabled={!hasSelected}
                            loading={deleteManyIsLoading}
                        >
                            {t("deleteSelectedItems", {
                                count: selectedRowKeys.length,
                            })}
                        </Button>
                    )}
                    {isEditable && userGroupId && (
                        <CreateAccess
                            userGroupId={Number(userGroupId)}
                            isModalVisible={isModalVisible}
                            setIsModalVisible={setIsModalVisible}
                        />
                    )}
                </Row>
                <Table
                    {...tableProps}
                    columns={columns}
                    rowKey="id"
                    rowSelection={rowSelection}
                    pagination={{
                        ...tableProps.pagination,
                        showSizeChanger: true,
                        showTotal: (total) => t("pagination.total", "Total {{total}} items", { total }),
                        locale: {
                            items_per_page: t("pagination.itemsPerPage", "/ page"),
                            jump_to: t("pagination.jumpTo", "Go to"),
                            jump_to_confirm: t("pagination.jumpToConfirm", "confirm"),
                            page: t("pagination.page", "Page"),
                            prev_page: t("pagination.prevPage", "Previous Page"),
                            next_page: t("pagination.nextPage", "Next Page"),
                            prev_5: t("pagination.prev5", "Previous 5 Pages"),
                            next_5: t("pagination.next5", "Next 5 Pages"),
                        },
                        className: "[&_.ant-pagination-options]:mr-6"
                    }}
                />
            </Card>
        </div>
    );
};

export default AccessTable;
