import { TFunction } from "i18next";

interface InviteData {
    email: string;
    name?: string;
    role?: string;
    invite_url: string;
    group_name: string;
    is_used: boolean;
}

export const generateBulkInviteEmail = (invites: InviteData[], t: TFunction): string => {
    // Filter out used invites
    const activeInvites = invites.filter(invite => !invite.is_used);

    if (activeInvites.length === 0) {
        return "";
    }

    const groupName = activeInvites[0].group_name;

    // Generate the email header
    const header = t(
        "invites.bulkEmail.header",
        "Hello,\n\nBelow are the invites to join group '{{groupName}}'. Each person can use their respective link to create an account:\n\n",
        { groupName }
    );

    // Generate the list of invites
    const invitesList = activeInvites
        .map((invite) => {
            const nameInfo = invite.name ? ` (${invite.name})` : "";
            const roleInfo = invite.role ? ` - ${invite.role}` : "";
            return `${invite.email}${nameInfo}${roleInfo}:\n${invite.invite_url}\n`;
        })
        .join("\n");

    // Generate the footer
    const footer = t(
        "invites.bulkEmail.footer",
        "\n\nBest regards,\n{{groupName}} Team",
        { groupName }
    );

    return `${header}${invitesList}${footer}`;

};
