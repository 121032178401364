import { DeleteButton, EditButton, ShowButton, useTable } from "@refinedev/antd";
import { BaseRecord, CrudFilters } from "@refinedev/core";
import { Card, Space, Table } from "antd";
import { useTranslation } from "react-i18next";
import { useState } from "react";

interface UsersTableProps {
    userGroupId: number;
}

export const UsersTable: React.FC<UsersTableProps> = ({ userGroupId }) => {
    const { t } = useTranslation();
    const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });

    const { tableProps } = useTable({
        syncWithLocation: true,
        resource: "users",
        filters: {
            permanent: [
                {
                    field: "user_group",
                    operator: "eq",
                    value: userGroupId,
                },
            ] as CrudFilters,
        },
        pagination: {
            mode: 'server',
            current: pagination.current,
            pageSize: pagination.pageSize,
        },
        meta: {
            offset: (pagination.current - 1) * pagination.pageSize,
            limit: pagination.pageSize,
        },
    });

    return (
        <Card
            className="mt-4"
            title={t("userGroupForm.fields.allGroupUsers", "All Group Users")}
            styles={{
                body: {
                    padding: 0,
                },
            }}
        >
            <Table
                {...tableProps}
                rowKey="id"
                pagination={{
                    ...tableProps.pagination,
                    showSizeChanger: true,
                    showTotal: (total) => t("pagination.total", "Total {{total}} items", { total }),
                    locale: {
                        items_per_page: t("pagination.itemsPerPage", "/ page"),
                        jump_to: t("pagination.jumpTo", "Go to"),
                        jump_to_confirm: t("pagination.jumpToConfirm", "confirm"),
                        page: t("pagination.page", "Page"),
                        prev_page: t("pagination.prevPage", "Previous Page"),
                        next_page: t("pagination.nextPage", "Next Page"),
                        prev_5: t("pagination.prev5", "Previous 5 Pages"),
                        next_5: t("pagination.next5", "Next 5 Pages"),
                    },
                    className: "[&_.ant-pagination-options]:mr-6"
                }}
            >
                <Table.Column
                    dataIndex="id"
                    title={t("tableHeaders.id", "ID")}
                />
                <Table.Column
                    dataIndex="name"
                    title={t("tableHeaders.name", "Name")}
                />
                <Table.Column
                    dataIndex="email"
                    title={t("tableHeaders.email", "Email")}
                />
                <Table.Column
                    dataIndex="usage"
                    title={t("tableHeaders.usage", "Usage")}
                />
                <Table.Column
                    title={t("tableHeaders.actions", "Actions")}
                    dataIndex="actions"
                    align="right"
                    render={(_, record: BaseRecord) => {
                        return (
                            <Space>
                                <EditButton
                                    hideText
                                    size="small"
                                    resource="users"
                                    recordItemId={record.id}
                                />
                                <ShowButton
                                    hideText
                                    size="small"
                                    resource="users"
                                    recordItemId={record.id}
                                />
                                <DeleteButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                    resource="users"
                                />
                            </Space>
                        );
                    }}
                />
            </Table>
        </Card>
    );
};
