import { DateField, NumberField, Show, TextField } from "@refinedev/antd";
import { useShow } from "@refinedev/core";
import { Typography } from "antd";
import { InvitesTable } from "components/user-groups/invites-table";
import { UsersTable } from "components/user-groups/users-table";
import AccessTable from "components/access/table";

const { Title } = Typography;

export const UserGroupShow = () => {
    const { queryResult } = useShow({});
    const { data, isLoading } = queryResult;

    const record = data?.data;
    const userGroupId = parseInt(record?.id);

    return (
        <Show isLoading={isLoading}>
            <Title level={5}>{"ID"}</Title>
            <NumberField value={record?.id ?? ""} />
            <Title level={5}>{"Name"}</Title>
            <TextField value={record?.name} />
            <Title level={5}>{"CreatedAt"}</Title>
            <DateField value={record?.createdAt} />
            <UsersTable userGroupId={userGroupId} />
            <InvitesTable userGroupId={userGroupId} isEditable={true} />
            <AccessTable userGroupId={userGroupId} isEditable={true} />
        </Show>
    );
};
