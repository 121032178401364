import {
    Authenticated,
    Refine,
    DataProvider,
    usePermissions,
    useIsAuthenticated,
} from "@refinedev/core";
import { DevtoolsPanel, DevtoolsProvider } from "@refinedev/devtools";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";
import { ErrorComponent, useNotificationProvider } from "@refinedev/antd";
import { App as AntdApp } from "antd";
import {
    ApiOutlined,
    BookOutlined,
    DashboardOutlined,
    TeamOutlined,
    UserOutlined,
    FileOutlined,
    FormOutlined,
} from "@ant-design/icons";
import "@refinedev/antd/dist/reset.css";
import routerBindings, {
    CatchAllNavigate,
    DocumentTitleHandler,
    UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
import {
    BrowserRouter,
    Navigate,
    Outlet,
    Route,
    Routes,
} from "react-router-dom";
import pluralize from "pluralize";
import { ThemedLayoutV2 } from "components/layout";
import { Header } from "components/layout/header";
import { ThemedSiderV2 } from "components/layout/sider";
import { ThemedTitleV2 } from "components/layout/title";
import { Footer } from "components/layout/footer";
import { dataProvider } from "api";

import { authProvider, useTokenRefresh } from "./authProvider";
import { ColorModeContextProvider } from "contexts/color-mode";
import { ForgotPassword } from "pages/forgotPassword";
import { Login } from "pages/login";
import "./tailwind.css";
import "./lang/i18n";
import { useTranslation } from "react-i18next";
import {
    Feature,
    mapFeatureStringsToFeatures,
} from "./utilities/availableFeatures";
import { DashboardPage } from "./pages/dashboard";
import {
    UserGroupCreate,
    UserGroupEdit,
    UserGroupList,
    UserGroupShow,
} from "./pages/user-groups";

import { UserCreate, UserEdit, UserList, UserShow } from "./pages/users";
import {
    AssistantCreate,
    AssistantEdit,
    AssistantList,
    AssistantShow,
} from "pages/assistants";
import {
    ContentDocumentShow,
    ContentDocumentList,
    ContentDocumentEdit,
    ContentDocumentCreate,
} from "pages/content-documents";

import {
    VerifyEmailPage,
    UpdatePasswordPage,
} from "components/pages/auth/components";
import { useEffect, useMemo, useState } from "react";
import moment from "moment/min/moment-with-locales";

import { i18nProvider } from "./lang/i18n";
import { ChatMessageHistoryShow } from "pages/dashboard/chat-message-history";
import { useCurrentUserGroup } from "utilities/getCurrentUserGroup";
import {
    EvaluationQuestionSetList,
    EvaluationQuestionSetCreate,
    EvaluationQuestionSetEdit,
    EvaluationQuestionSetShow,
} from "pages/evaluation-question-sets";
import {
    EvaluationList,
    EvaluationCreate,
    EvaluationEdit,
    EvaluationShow,
} from "pages/evaluations";
import InfoPage from "pages/info";
import InfoEditPage from "pages/info/edit";
import FirstTimeInformationPopup from "components/info/first-time-information-popup";
import { useNavigate } from "react-router-dom";
import { Spin } from "antd";
import { AcceptInvitePage } from "pages/register/accept-invite";

interface FeatureFilterProps {
    setFilteredFeatures: React.Dispatch<React.SetStateAction<Feature[]>>;
    setHomeRedirect: React.Dispatch<React.SetStateAction<string>>;
    setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
    setIsAuthLoading: React.Dispatch<React.SetStateAction<boolean>>;
}
const PermissionBasedHomeRedirect: React.FC<{ homeRedirect: string }> = ({
    homeRedirect,
}) => {
    return <Navigate to={homeRedirect} replace />;
};

function getAllFeatureNames(): Feature[] {
    return Object.keys(Feature)
        .filter((key) => isNaN(Number(key)))
        .map((key) => Feature[key as keyof typeof Feature]);
}
const allFeatureNames = getAllFeatureNames();

const FeatureFilter: React.FC<FeatureFilterProps> = ({
    setIsAuthenticated,
    setIsAuthLoading,
    setFilteredFeatures,
    setHomeRedirect,
}) => {
    const navigate = useNavigate();
    const { features: userGroupFeatures, isExpired } = useCurrentUserGroup();
    const { data: permissionsData } = usePermissions({});

    const { isAuthenticatedRefine, isLoading: isAuthLoadingRefine } = useIsAuthenticated();

    useEffect(() => {
        setIsAuthenticated(isAuthenticatedRefine);
        setIsAuthLoading(isAuthLoadingRefine);
    }, [isAuthenticatedRefine, isAuthLoadingRefine]);

    const filteredFeatures = useMemo(() => {
        if (permissionsData?.includes("admin")) {
            return allFeatureNames;
        }
        if (allFeatureNames.includes(Feature.GroupBasedCustomization)) {
            return allFeatureNames.filter((feature) =>
                userGroupFeatures?.includes(feature)
            );
        }
        return allFeatureNames;
    }, [permissionsData, allFeatureNames, userGroupFeatures]);

    useEffect(() => {
        if (permissionsData?.includes("admin")) {
            setHomeRedirect("/dashboard");
        } else if (
            filteredFeatures.includes(Feature.Assistants) &&
            !filteredFeatures.includes(Feature.Documents)
        ) {
            setHomeRedirect("/assistants");
        } else {
            setHomeRedirect("/content-documents/list/");
        }
    }, [permissionsData, filteredFeatures, setHomeRedirect]);

    useEffect(() => {
        setFilteredFeatures((prevFeatures) => {
            const areFeaturesEqual =
                prevFeatures.length === filteredFeatures.length &&
                prevFeatures.every(
                    (feature, index) => feature === filteredFeatures[index]
                );

            if (!areFeaturesEqual) {
                setFilteredFeatures(
                    mapFeatureStringsToFeatures(filteredFeatures)
                );
                return mapFeatureStringsToFeatures(filteredFeatures);
            }
            return prevFeatures;
        });
    }, [filteredFeatures, setFilteredFeatures]);

    useEffect(() => {
        if (!permissionsData?.includes("admin") && isExpired) {
            navigate("/expired");
        }
    }, [isExpired, permissionsData]);

    return null;
};

type Route = {
    path: string;
    feature: string;
    element: JSX.Element;
    children?: Route[];
};

function App() {
    const apiUrl = window.location.origin + "/api";
    useTokenRefresh();
    const { t, i18n } = useTranslation();

    const [features, setFeatures] = useState<Feature[]>([]);
    const [homeRedirect, setHomeRedirect] = useState<string>("/");
    const [isLoading, setIsLoading] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isAuthLoading, setIsAuthLoading] = useState(true);

    useEffect(() => {
        i18n.changeLanguage("sl");
        // change the language of the moment library
        moment.locale("sl");
    }, []); // This will only re-run if defaultLanguage changes

    const routes = useMemo(
        () => [
            {
                path: "/dashboard",
                feature: Feature.Dashboard,
                children: [
                    {
                        path: "",
                        element: <DashboardPage />,
                    },
                    {
                        path: "chat-message-history/:id",
                        element: <ChatMessageHistoryShow />,
                    },
                ],
            },
            {
                path: "/assistants",
                feature: Feature.Assistants,
                children: [
                    {
                        path: "",
                        element: <AssistantList />,
                    },
                    {
                        path: "create",
                        element: <AssistantCreate />,
                    },
                    {
                        path: "edit/:id",
                        element: <AssistantEdit />,
                    },
                    {
                        path: "show/:id",
                        element: <AssistantShow />,
                    },
                ],
            },
            {
                path: "/assistants",
                feature: Feature.AssistantsAdmin,
                children: [
                    {
                        path: "",
                        element: <AssistantList />,
                    },
                    {
                        path: "create",
                        element: <AssistantCreate />,
                    },
                    {
                        path: "edit/:id",
                        element: <AssistantEdit />,
                    },
                    {
                        path: "show/:id",
                        element: <AssistantShow />,
                    },
                ],
            },
            {
                path: "/users",
                feature: Feature.Users,

                children: [
                    { path: "", element: <UserList /> },
                    {
                        path: "create",
                        element: <UserCreate />,
                    },
                    {
                        path: "edit/:id",
                        element: <UserEdit />,
                    },
                    {
                        path: "show/:id",
                        element: <UserShow />,
                    },
                ],
            },
            {
                path: "/user-groups",
                feature: Feature.UserGroups,
                children: [
                    {
                        path: "",
                        element: <UserGroupList />,
                    },
                    {
                        path: "create",
                        element: <UserGroupCreate />,
                    },
                    {
                        path: "edit/:id",
                        element: <UserGroupEdit />,
                    },
                    {
                        path: "show/:id",
                        element: <UserGroupShow />,
                    },
                ],
            },
            {
                path: "/content-documents",
                feature: Feature.Documents,
                children: [
                    {
                        path: "list",
                        element: <ContentDocumentList />,
                    },
                    {
                        path: "show/:id",
                        element: <ContentDocumentShow />,
                    },
                    {
                        path: "create",
                        element: <ContentDocumentCreate />,
                    },
                    {
                        path: "edit/:id",
                        element: <ContentDocumentEdit />,
                    },
                ],
            },
            {
                path: "/evaluation-question-sets",
                feature: Feature.Evaluations,
                children: [
                    {
                        path: "list",
                        element: <EvaluationQuestionSetList />,
                    },
                    {
                        path: "create",
                        element: <EvaluationQuestionSetCreate />,
                    },
                    {
                        path: "edit/:id",
                        element: <EvaluationQuestionSetEdit />,
                    },
                    {
                        path: "show/:id",
                        element: <EvaluationQuestionSetShow />,
                    },
                ],
            },
            {
                path: "/evaluations",
                feature: Feature.Evaluations,
                children: [
                    {
                        path: "",
                        element: <EvaluationList />,
                    },
                    {
                        path: "create",
                        element: <EvaluationCreate />,
                    },
                    {
                        path: "edit/:id",
                        element: <EvaluationEdit />,
                    },
                    {
                        path: "show/:id",
                        element: <EvaluationShow />,
                    },
                ],
            },
            {
                path: "/information",
                element: <InfoPage />,
            },
            {
                path: "/information/edit",
                element: <InfoEditPage />,
            },
            {
                path: "*",
                element: <ErrorComponent />,
            },
        ],
        []
    );

    useEffect(() => {
        if (!isAuthLoading) {
            if (isAuthenticated) {
                // Only set loading to false when features are loaded for authenticated users
                if (features.length > 0) {
                    setIsLoading(false);
                }
            } else {
                // For unauthenticated users, we don't need to wait for features
                setIsLoading(false);
            }
        }
    }, [isAuthLoading, isAuthenticated, features]);

    return (
        <BrowserRouter>
            <ColorModeContextProvider>
                <RefineKbarProvider>
                    <AntdApp>
                        <DevtoolsProvider>
                            <Refine
                                // @ts-expect-error - something with antd's i18n provider
                                i18nProvider={i18nProvider}
                                dataProvider={
                                    dataProvider(
                                        apiUrl
                                    ) as unknown as DataProvider
                                }
                                notificationProvider={useNotificationProvider}
                                routerProvider={routerBindings}
                                authProvider={authProvider}
                                resources={[
                                    {
                                        name: "dashboard",
                                        list: "/dashboard",
                                        meta: {
                                            adminOnly: true,
                                            icon: <DashboardOutlined />,
                                            label: t(
                                                "resources.dashboard",
                                                "Dashboard"
                                            ),
                                            hide: !features?.includes(
                                                Feature.Dashboard
                                            ),
                                        },
                                    },
                                    {
                                        name: "user-groups",
                                        list: "/user-groups",
                                        create: "/user-groups/create",
                                        edit: "/user-groups/edit/:id",
                                        show: "/user-groups/show/:id",
                                        meta: {
                                            canDelete: true,
                                            adminOnly: true,
                                            icon: <TeamOutlined />,
                                            label: t(
                                                "resources.groups",
                                                "Groups"
                                            ),
                                            hide: !features?.includes(
                                                Feature.UserGroups
                                            ),
                                        },
                                    },
                                    {
                                        name: "users",
                                        list: "/users",
                                        create: "/users/create",
                                        edit: "/users/edit/:id",
                                        show: "/users/show/:id",
                                        meta: {
                                            label: t(
                                                "resources.users",
                                                "Users"
                                            ),
                                            canDelete: true,
                                            adminOnly: true,
                                            icon: <UserOutlined />,
                                            hide: !features?.includes(
                                                Feature.Users
                                            ),
                                        },
                                    },
                                    {
                                        identifier: "assistants-admin",
                                        name: "assistants",
                                        list: "/assistants",
                                        create: "/assistants/create",
                                        edit: "/assistants/edit/:id",
                                        show: "/assistants/show/:id",
                                        meta: {
                                            label: t(
                                                "resources.assistants",
                                                "Assistants"
                                            ),
                                            canDelete: true,
                                            icon: <ApiOutlined />,
                                            adminOnly: true,
                                            hide: !features?.includes(
                                                Feature.AssistantsAdmin
                                            ),
                                        },
                                    },
                                    {
                                        identifier: "assistants-user",
                                        name: "assistants",
                                        list: "/assistants",
                                        create: "/assistants/create",
                                        edit: "/assistants/edit/:id",
                                        show: "/assistants/show/:id",
                                        meta: {
                                            label: t(
                                                "resources.assistants",
                                                "Assistants"
                                            ),
                                            canDelete: true,
                                            icon: <ApiOutlined />,
                                            adminOnly: false,
                                            hide:
                                                !features?.includes(
                                                    Feature.Assistants
                                                ) ||
                                                features?.includes(
                                                    Feature.AssistantsAdmin
                                                ),
                                        },
                                    },
                                    {
                                        name: "content-documents",
                                        list: "/content-documents/list",
                                        show: "/content-documents/show/:id",
                                        create: "/content-documents/create",
                                        edit: "/content-documents/edit/:id",
                                        meta: {
                                            label: t(
                                                "resources.documents",
                                                "Documents"
                                            ),
                                            canDelete: true,
                                            icon: <BookOutlined />,
                                            hide: !features?.includes(
                                                Feature.Documents
                                            ),
                                        },
                                    },
                                    {
                                        name: "evaluation-question-sets",
                                        list: "/evaluation-question-sets/list",
                                        create: "/evaluation-question-sets/create",
                                        edit: "/evaluation-question-sets/edit/:id",
                                        show: "/evaluation-question-sets/show/:id",
                                        meta: {
                                            label: t(
                                                "resources.evaluationQuestionSets",
                                                "Question Sets"
                                            ),
                                            canDelete: true,
                                            adminOnly: true,
                                            icon: <FileOutlined />,
                                            hide: true,
                                        },
                                    },
                                    {
                                        name: "evaluations",
                                        list: "/evaluations",
                                        create: "/evaluations/create",
                                        edit: "/evaluations/edit/:id",
                                        show: "/evaluations/show/:id",
                                        meta: {
                                            label: t(
                                                "resources.evaluations",
                                                "Evaluations"
                                            ),
                                            canDelete: true,
                                            icon: <FormOutlined />,
                                            adminOnly: true,
                                            hide: !features?.includes(
                                                Feature.Evaluations
                                            ),
                                        },
                                    },
                                ]}
                                options={{
                                    syncWithLocation: true,
                                    warnWhenUnsavedChanges: true,
                                    useNewQueryKeys: true,
                                    projectId: "gP4tlt-ePnob0-W0sNBr",
                                    textTransformers: {
                                        plural: (text) => {
                                            if (i18n.language === "sl") {
                                                return text;
                                            } else {
                                                return pluralize.plural(text);
                                            }
                                        },
                                    },
                                }}
                            >
                                <FeatureFilter
                                    setIsAuthenticated={setIsAuthenticated}
                                    setIsAuthLoading={setIsAuthLoading}
                                    setFilteredFeatures={setFeatures}
                                    setHomeRedirect={setHomeRedirect}
                                />
                                <FirstTimeInformationPopup />
                                {isLoading ? (
                                    <div className="flex items-center justify-center h-screen">
                                        <Spin size="large" />
                                    </div>
                                ) : (
                                    <Routes>
                                        <Route
                                            element={
                                                <Authenticated
                                                    key="authenticated-inner"
                                                    fallback={
                                                        <CatchAllNavigate to="/login" />
                                                    }
                                                >
                                                    <ThemedLayoutV2
                                                        Header={Header}
                                                        Sider={ThemedSiderV2}
                                                        Title={ThemedTitleV2}
                                                        Footer={Footer}
                                                    >
                                                        <Outlet />
                                                    </ThemedLayoutV2>
                                                </Authenticated>
                                            }
                                        >
                                            {routes.map(
                                                (route, index) => (
                                                    <Route
                                                        key={index}
                                                        path={route.path}
                                                        element={route.element}
                                                    >
                                                        {route.children?.map(
                                                            (child, idx) => (
                                                                <Route
                                                                    key={idx}
                                                                    path={
                                                                        child.path
                                                                    }
                                                                    element={
                                                                        child.element
                                                                    }
                                                                />
                                                            )
                                                        )}
                                                    </Route>
                                                )
                                            )}
                                            <Route
                                                index
                                                element={
                                                    <PermissionBasedHomeRedirect
                                                        homeRedirect={
                                                            homeRedirect
                                                        }
                                                    />
                                                }
                                            />
                                            <Route
                                                path="*"
                                                element={<ErrorComponent />}
                                            />
                                        </Route>
                                        <Route
                                            path="/login"
                                            element={<Login />}
                                        />
                                        <Route
                                            path="/forgot-password"
                                            element={<ForgotPassword />}
                                        />
                                        <Route
                                            path="/account/password/reset/key/:key"
                                            element={<UpdatePasswordPage />}
                                        />
                                        <Route
                                            path="/account/verify-email/:token"
                                            element={<VerifyEmailPage />}
                                        />
                                        <Route
                                            path="/accept-invite"
                                            element={<AcceptInvitePage />}
                                        />
                                    </Routes>
                                )}
                                <RefineKbar />
                                <UnsavedChangesNotifier />
                                <DocumentTitleHandler />
                            </Refine>
                            <DevtoolsPanel />
                        </DevtoolsProvider>
                    </AntdApp>
                </RefineKbarProvider>
            </ColorModeContextProvider>
        </BrowserRouter>
    );
}

export default App;
